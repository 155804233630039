// extracted by mini-css-extract-plugin
export var cta = "image-with-cta-module--cta--3f067";
export var ctaContainer = "image-with-cta-module--ctaContainer--6a143";
export var descriptionContent = "image-with-cta-module--descriptionContent--82af5";
export var descriptionContentContainer = "image-with-cta-module--descriptionContentContainer--d0ef0";
export var descriptionCopy = "image-with-cta-module--descriptionCopy--4875c";
export var headline = "image-with-cta-module--headline--6baea";
export var headlineContentContainer = "image-with-cta-module--headlineContentContainer--c1147";
export var picture = "image-with-cta-module--picture--509d4";
export var subheadline = "image-with-cta-module--subheadline--e0b00";
export var wrapper = "image-with-cta-module--wrapper--77dc8";