import React from 'react';
import { graphql } from 'gatsby';

//@ts-ignore
import * as styles from './image-with-cta.module.css';
import CtaLink from '../Global/CtaLink';
import RichText from '../Global/RichText';
import { BuildLinkHref } from '../Global/BuildLinkHref';

import {
    KontentStringValue,
    CTAConfiguration,
    GeneralImages,
    BrandColor,
    KontentRichText,
    KontentRadioMultiChoice,
} from '../../../types/KontentProps';
import { getColor, getMediaData, getMultiChoice } from '../Global/DataUtils';
import PlainTextElement from '../Global/Headlines';

type ShowHide = 'show' | 'hide';

interface Props extends CTAConfiguration, GeneralImages {
    sub_headline?: KontentStringValue;
    show_cta: KontentRadioMultiChoice<ShowHide>;
    headline?: KontentStringValue;
    content_panel__content_panel_color?: BrandColor;
    content_panel__content_panel_font_color?: BrandColor;
    description_copy?: KontentRichText;
    component_anchor_name: KontentStringValue;
}

const ImageWithCta: React.FC<Props> = ({
    sub_headline,
    show_cta,
    headline,
    general_large___small_image_selection__small,
    general_large___small_image_selection__large,
    description_copy,
    cta_configuration__cta_window_action,
    cta_configuration__cta_location_anchor_point,
    cta_configuration__cta_location,
    cta_configuration__cta_label,
    content_panel__content_panel_font_color,
    content_panel__content_panel_color,
    component_anchor_name,
    ...props
}) => {
    const largeImage = getMediaData(
        general_large___small_image_selection__large
    );

    const smallImage = getMediaData(
        general_large___small_image_selection__small
    );
    const anchorTarget =
        getMultiChoice(cta_configuration__cta_window_action) === 'new_window'
            ? '_new'
            : '_self';
    const contentPanelFontColor = getColor(
        content_panel__content_panel_font_color
    ) as any;
    const contentPanelColor = getColor(
        content_panel__content_panel_color
    ) as any;
    const authorStyles = {
        color: contentPanelFontColor,
        backgroundColor: contentPanelColor,
    };

    const renderCta = () => {
        if (
            !cta_configuration__cta_label?.value ||
            getMultiChoice(show_cta) === 'hide'
        ) {
            return null;
        }

        return (
            <div className={styles.ctaContainer}>
                {cta_configuration__cta_location && (
                    <CtaLink
                        href={BuildLinkHref(cta_configuration__cta_location)}
                        target={anchorTarget}
                    >
                        {cta_configuration__cta_label?.value}
                    </CtaLink>
                )}
            </div>
        );
    };

    return (
        <section className={styles.wrapper} id={component_anchor_name?.value}>
            {largeImage ? (
                <picture className={styles.picture}>
                    {smallImage && (
                        <source
                            media="(max-width:500px)"
                            srcSet={smallImage.url}
                        />
                    )}
                    <img src={largeImage.url} alt={largeImage.description} />
                </picture>
            ) : null}

            <div
                className={styles.headlineContentContainer}
                style={authorStyles}
            >
                {headline && (
                    <h2 className={styles.headline}>
                        <PlainTextElement text={headline} />
                    </h2>
                )}
                {sub_headline && (
                    <p className={styles.subheadline}>{sub_headline.value}</p>
                )}
            </div>
            <div className={styles.descriptionContentContainer}>
                <div className={styles.descriptionContent}>
                    {description_copy?.value && (
                        <div className={styles.descriptionCopy}>
                            {RichText(description_copy)}
                        </div>
                    )}
                    {renderCta()}
                </div>
            </div>
        </section>
    );
};

export default ImageWithCta;

export const fragmentImageWithCta = graphql`
    fragment ImageWithCta on kontent_item_component___image_with_cta {
        id
        elements {
            component_anchor_name {
                value
            }
            sub_headline {
                value
            }
            show_cta {
                value {
                    codename
                }
            }
            headline {
                value
            }
            general_large___small_image_selection__small {
                value {
                    ... on kontent_item_media___image {
                        elements {
                            file {
                                value {
                                    description
                                    url
                                }
                            }
                        }
                    }
                }
            }
            general_large___small_image_selection__large {
                value {
                    ... on kontent_item_media___image {
                        elements {
                            file {
                                value {
                                    description
                                    url
                                }
                            }
                        }
                    }
                }
            }
            description_copy {
                value
            }
            cta_configuration__cta_window_action {
                value {
                    codename
                }
            }
            cta_configuration__cta_location_anchor_point {
                value
            }
            cta_configuration__cta_location {
                ...kontentCtaLocation
            }
            cta_configuration__cta_label {
                value
            }
            content_panel__content_panel_font_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                        }
                    }
                }
            }
            content_panel__content_panel_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                        }
                    }
                }
            }
            component_anchor_name {
                value
            }
        }
    }
`;
