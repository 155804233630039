import React from 'react';
import { KontentStringValue } from '../../../types/KontentProps';
import parse from 'html-react-parser';

interface Headline {
    text: KontentStringValue;
}

const PlainTextElement: React.FC<Headline> = ({ text }) => {
    if (!text || !(typeof text?.value === 'string')) return null;
    const words = text?.value?.split(' ');

    const parsedText = words
        ?.map((word, idx) => {
            if (word.includes('®')) {
                const wordParts = word.split('®');

                return `${wordParts[0]}<sup>&reg;</sup>`;
            }

            if (word.includes('--')) {
                const wordParts = word.split('--');

                return wordParts
                    .map((p, idx) => {
                        if (idx != wordParts.length - 1) {
                            return `${p}&shy;`;
                        }

                        return p;
                    })
                    .join('');
            }

            return word;
        })
        ?.join(' ');

    if (!parsedText) return <>{text.value}</>;

    return <>{parse(parsedText)}</>;
};

export default PlainTextElement;
